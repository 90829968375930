@use '../../../../../styles';
@import '../../../const.module';

$cell-height: 20px;
$cell-border: 2px;
$hour-rows: 4;
$hours: 11;

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.weekDays {
  display: grid;
  grid-template-columns: $first-column-size repeat($days, 1fr);
  border-bottom: 2px solid styles.$white-2;
  align-items: center;
  grid-column-gap: $column-gap;
  p {
    text-align: center;
  }
  > p:first-of-type {
    padding: 0.8rem;
    text-align: left;
  }
}

.hours {
  background-color: styles.$blue-4;
  border-radius: 10px;
  padding: $hour-text-vertical-padding 0 $hour-text-vertical-padding 1.2rem;
  display: flex;
  flex-direction: column;
  gap: calc($cell-height * $hour-rows - $hour-text-height);
}

.calendarDay {
  @extend .gray-shadow-8;
  position: relative;
  display: grid;
  margin: calc($hour-text-vertical-padding + $hour-text-height / 2) 0;
  grid-template-columns: repeat($animators, 1fr);
  grid-template-rows: repeat(calc($hours * $hour-rows), $cell-height);
  border-radius: 10px;
  background-color: styles.$white-4;
  overflow: clip;

  .calendarCell {
    border-bottom: $cell-border solid styles.$white-2;
    border-right: $cell-border solid styles.$white-2;
  }

  .calendarEntry {
    cursor: pointer;
    width: calc($animator-width - $cell-border);
  }
}

:export {
  cellHeight: $cell-height;
  cellBorder: $cell-border;
}
