@use '../../../../styles';

.container {
  position: absolute;
  width: fit-content;
  z-index: 10;
}

.popup {
  @extend .gray-shadow-9;
  color: styles.$white-3;
  border-radius: 10px;
  padding: 0.8rem 1.8rem 1.5rem 1.8rem;
}

.close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}
