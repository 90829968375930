@use '../../../../../styles';
@import '../../../const.module';

$cell-height: 40px;
$cell-border: 2px;

$day-number-width: 0;
$entry-width: calc(1 - $day-number-width);

$day-rows: 4;
$hour-ranges-gap: 1.5rem;
$day-gap: calc($hour-ranges-gap + $hour-text-vertical-padding * 2 + $hour-text-height);

.weekDaysWrapper {
  display: grid;
  grid-template-columns: $first-column-size repeat($days, 1fr);
  align-items: center;
  grid-column-gap: $column-gap;

  .weekDays {
    display: grid;
    grid-column: span $days;
    grid-template-columns: repeat($days, 1fr);
    border-bottom: 2px solid styles.$white-2;
    align-items: center;
    grid-column-gap: $column-gap;
  }
}

.weekCalendar {
  margin-top: $hour-ranges-gap;
}

.weekDay {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
}

.hourRanges {
  display: flex;
  flex-direction: column;
  gap: $hour-ranges-gap;
}

.hours {
  background-color: styles.$gray-2;
  border-radius: 10px;
  padding: $hour-text-vertical-padding 1.2rem;
  gap: calc($cell-height - $hour-text-height);
  flex-direction: column;
  display: flex;
  opacity: 70%;
}

.calendarWeek {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: $day-gap;
  align-items: start;
  margin: calc($hour-text-vertical-padding + $hour-text-height / 2) 0;
}

.calendarDay {
  @extend .gray-shadow-8;
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat($day-rows, $cell-height);
  border-radius: 10px;
  background-color: styles.$white-4;

  > :last-child {
    border-bottom: none !important;
  }

  .calendarCell {
    border-bottom: $cell-border solid styles.$white-2;

    > p {
      position: absolute;
      top: -2.5rem;
      width: 100%;
      text-align: center;
    }
  }

  .calendarColumns {
    position: absolute;
    left: calc($day-number-width * 100%);
    width: calc($entry-width * 100%);
    top: 0;
    height: 100%;
    display: grid;
    grid-template-columns: repeat($animators, 1fr);

    > :first-child {
      border-left: none !important;
    }

    .calendarColumn {
      border-left: $cell-border solid styles.$white-2;
    }
  }

  .calendarEntry {
    width: calc($entry-width * $animator-width - $cell-border);
  }
}

:export {
  cellHeight: $cell-height;
  cellBorder: $cell-border;
  entryWidth: $entry-width;
}
