@use '../../../../styles';

$activeColor: #000000;
$inactiveColor: #89939e;

.wrapper {
  position: relative;
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  margin-left: 7em;
  @media (max-width: 768px) {
    margin-left: 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  .stepWrapper {
    img {
      height: 287px;
      object-fit: contain;
      @media (max-width: 768px) {
        height: auto;
      }
    }
  }
}

.steps {
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  @media (max-width: 768px) {
    flex-direction: row;
    height: 100px;
    width: var(--site-content-width);
  }
  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid styles.$black-1;
    color: styles.$black-1;
    background-color: styles.$white-3;

    &.active {
      color: styles.$white-3;
      background-color: styles.$black-1;
    }
  }

  .stepLine {
    width: 0;
    border: 1px solid styles.$gray-2;
    flex-grow: 1;
  }
}

.formGrid {
  margin-top: 5.5em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 2em;
  grid-column-gap: 3em;
  text-align: start;
  align-items: start;
  > div {
    width: 363px;
    @media (max-width: 1300px) {
      width: 100%;
    }
  }
  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: center;
    width: var(--site-content2-width);
  }
}

.buttons {
  display: flex;
  gap: 4em;
  align-items: center;
  margin: 7em 0;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    cursor: pointer;
  }

  > :nth-child(2) {
    width: 200px;
  }
}

.counter {
  width: 90px;
}

.switch {
  display: flex;
  height: 30px;
  margin: 2em auto;
  gap: 5em;
  @media (max-width: 1000px) {
    display: flex;
    width: var(--site-content2-width);
  }
}

.stepButton {
  width: 212px;
  height: 36px;
  > div {
    width: 20px;
    height: 20px;
  }
}

.buttonsWrapper > div {
  flex-direction: row !important;

  @media (max-width: 768px) {
    flex-direction: column !important;
  }
}

.checkboxWrapper {
  width: 80%;
  margin: 4rem auto 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  label {
    display: flex;
    gap: 2rem;
    a {
      font-size: 1.4rem;
      text-decoration: underline;
    }
    p {
      font-size: 1.4rem;
      text-align: left;
    }
  }
}

.whoYouAre {
  border: 1px solid #e1e3e6;
  border-radius: 8px;
  background-color: #ffffff;
  width: 363px;
  padding: 0.8rem 1.6rem;
  @media (max-width: 1300px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  label {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  p {
    color: $inactiveColor;
    font-size: 1.6rem;
  }
  input,
  label {
    font-size: 1.4rem;
  }
}

.active {
  color: $activeColor;
}

.inactive {
  color: $inactiveColor;
}

.iamDifferent {
  display: flex;
  gap: 2rem;
  height: 32px;
}

.underlinedPlaceholder {
  padding: 5px;
  width: 160px;
  font-size: 16px;
  border-bottom: 1px solid black;
}
