@use '../../../../styles';
@import '../../const.module';

.pagination {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > div {
    display: flex;
    gap: 1rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.separator {
  width: 2px;
  height: 18px;
  background-color: styles.$blue-4;
  position: absolute;
  top: 50%;
  right: calc($column-gap * -1 / 2);
  transform: translateX(50%) translateY(-50%);
}

.hour {
  color: styles.$white-3;
  display: flex;
  align-items: center;
  gap: 1rem;
  height: $hour-text-height;

  > p {
    width: 40%;
    text-align: center;
  }

  .hourLine {
    width: 60%;
    height: 2px;
    background-color: styles.$white-3;
  }
}

.weekCalendar {
  display: grid;
  grid-template-columns: $first-column-size repeat($days, 1fr);
  grid-column-gap: $column-gap;
}

.calendarEntry {
  position: absolute;
  border-radius: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: styles.$white-3;

  > p {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 100%;
    padding: 0.5rem 0;
    text-align: center;
  }
}

.weekAnimators {
  @extend .gray-shadow-2;
  display: grid;
  grid-template-columns: $first-column-size repeat($days, 1fr);
  background-color: styles.$white-5;
  border-radius: 10px;
  border: 1px solid styles.$white-2;
  grid-column-gap: $column-gap;

  > p:first-of-type {
    padding: 0.8rem;
  }
}

.animators {
  position: relative;
  display: grid;
  grid-template-columns: repeat($animators, 1fr);

  > :first-child {
    border-left: none !important;
  }

  .animator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 2px solid styles.$white-2;
    cursor: pointer;
    user-select: none;
  }
}

.ribbon {
  width: 17px;
  height: 3px;
  border-radius: 10px 0;
}
