@use '../../styles';

.wrapper {
  overflow-x: auto;
}

.container {
  width: var(--site-max-width);
  background-color: styles.$white-3;
  padding: 1rem 5rem 1rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  min-height: 1000px;
}
