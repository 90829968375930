@use '../../../../styles';

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.views {
  display: flex;
  gap: 2.5rem;
  justify-self: center;
}

.date {
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 2rem;

  > p {
    color: styles.$gray-2;
  }

  .navigationButton {
    @extend .gray-shadow-7;
    display: flex;
    cursor: pointer;
    user-select: none;
    background-color: styles.$gray-15;
    border-radius: 50%;
    border: 2px solid styles.$white-2;
  }
}
