@use '../../../../../styles';

.container {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: 5em !important;

  .info {
    display: flex;
    gap: 8rem;
    align-items: center;
    justify-content: space-between;
  }

  .contactInfo {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
  }
}
