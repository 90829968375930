@use 'colors';

// Gray
.gray-shadow-1 {
  box-shadow: 0 4px 2px -2px rgba(#1b2e5e, 2%);
}

.gray-shadow-2 {
  box-shadow: 0 4px 8px -2px rgba(#1b2e5e, 4%);
}

.gray-shadow-3 {
  box-shadow: 0 2px 4px -2px rgba(#1b2e5e, 8%);
}

.gray-shadow-4 {
  box-shadow: 0 4px 8px -6px rgba(#1b2e5e, 3%),
    0 16px 8px -4px rgba(#1b2e5e, 3%);
}

.gray-shadow-5 {
  box-shadow: 0 8px 24px -4px rgba(#1b2e5e, 8%);
}

.gray-shadow-6 {
  box-shadow: 0 8px 24px -4px rgba(#1b2e5e, 12%);
}

.gray-shadow-7 {
  box-shadow: 0 2px 5px 0 rgba(#26334d, 3%);
}

.gray-shadow-8 {
  box-shadow: 0 16px 8px -4px rgba(#1b2e5e, 3%), 0px 4px 8px -6px rgba(#1b2e5e, 3%);
}

.gray-shadow-9 {
  box-shadow: 0 22px 44px -4px rgba(#9f9f9f, 15%);
}

.gray-inner-shadow-1 {
  box-shadow: inset 0 1px 2px rgba(#1b2e5e, 30%);
}

// Blue
.blue-shadow-1 {
  box-shadow: 0 4px 2px -2px rgba(#4e73f8, 2%);
}

.blue-shadow-2 {
  box-shadow: 0 2px 4px -2px rgba(#4e73f8, 8%);
}

.blue-shadow-3 {
  box-shadow: 0 4px 8px -2px rgba(#4e73f8, 4%);
}

.blue-shadow-4 {
  box-shadow: 0 4px 8px -6px rgba(#4e73f8, 3%),
    0 16px 8px -4px rgba(#4e73f8, 3%);
}

.blue-shadow-5 {
  box-shadow: 0 8px 24px -4px rgba(#4e73f8, 8%);
}

.blue-shadow-6 {
  box-shadow: 0 0 8px rgba(#597cfa, 32%);
}

.blue-inner-shadow-1 {
  box-shadow: inset 0 1px 2px rgba(#3e45ef, 30%);
}

.black-shadow-1 {
  box-shadow: 0 0 0 4px rgba(colors.$black-1, 8%);
}
