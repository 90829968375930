@use '../../styles';

$first-column-size: 0.5fr;
$days: 5;
$animators: var(--calendar-animators);
$column-gap: 1.5rem;
$animator-width: calc(100% / $animators);

$hour-text-height: 20px;
$hour-text-vertical-padding: 0.2rem;

.ribbon1 {
  background-color: styles.$ribbon-1;
}

.ribbon2 {
  background-color: styles.$ribbon-2;
}

.ribbon3 {
  background-color: styles.$ribbon-3;
}

.ribbon4 {
  background-color: styles.$ribbon-4;
}

.ribbon5 {
  background-color: styles.$ribbon-5;
}

.ribbon6 {
  background-color: styles.$ribbon-6;
}

.ribbon7 {
  background-color: styles.$ribbon-7;
}

.ribbon8 {
  background-color: styles.$ribbon-8;
}

:export {
  animatorWidth: $animator-width;
}
