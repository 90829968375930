@use '../../styles';

.button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.6rem;
  gap: 0.4rem;
  border-radius: 4px;
  border: 1px solid transparent;
  &:disabled {
    color: styles.$gray-5;
    background-color: styles.$white-2;
    border: 1px solid styles.$gray-6;
    svg {
      fill: styles.$gray-5;
      stroke: styles.$gray-5;
    }
  }
}

.login {
  color: styles.$white-5;
  background-color: styles.$blue-4;
  &:hover {
    filter: brightness(105%);
  }
}

.registerOutline {
  background-color: transparent;
  border: 1px solid styles.$blue-4;
  &:hover {
    background-color: styles.$blue-4;
    color: styles.$white-5;
  }
}

.register {
  color: styles.$white-5;
  background-color: styles.$green-1;
  &:hover {
    filter: brightness(105%);
  }
}

.loginOutline {
  width: 256px;
  height: 36px;
  background-color: transparent;
  border: 1px solid styles.$green-1;
  @media (max-width: 768px) {
    width: 60vw;
  }
  &:hover {
    background-color: styles.$green-1;
    color: styles.$white-5;
  }
}

.forgotPasswordOutline {
  color: styles.$green-1;
  background-color: transparent;
  border: 1px solid styles.$green-1;
  border-radius: 8px;
  flex-shrink: 0;
  width: 170px;
  &:hover {
    background-color: styles.$green-1;
    color: styles.$white-5;
  }
}

.appointment {
  background-color: styles.$white-2;
  color: styles.$black-1;
  width: 132px;
  &:hover {
    filter: brightness(105%);
  }
}

.registerStep {
  background-color: styles.$white-2;
  color: styles.$black-1;
  &:hover {
    filter: brightness(105%);
  }
}

.chooseSchool {
  background-color: transparent;
  color: styles.$blue-4;
  width: max-content;
  margin: 0 auto;
  &:hover {
    text-decoration: underline;
  }
}

.chooseSchoolAlt {
  background-color: transparent;
  color: styles.$white-2;
  &:hover {
    text-decoration: underline;
  }
}

.contests {
  background-color: transparent;
  color: styles.$blue-4;
  &:hover {
    text-decoration: underline;
  }
}

.gallery {
  background-color: styles.$white-2;
  color: styles.$blue-4;
  &:hover {
    filter: brightness(105%);
  }
}

.voting {
  background-color: styles.$orange-1;
  color: styles.$white-3;
  &:hover {
    background-color: styles.$white-3;
    color: styles.$orange-1;
    & svg {
      fill: styles.$orange-1;
    }
  }
}

.ranking {
  background-color: transparent;
  color: styles.$white-2;
  width: max-content;
  margin-left: 0;
  &:hover {
    text-decoration: underline;
  }
}

.browseSchoolGallery {
  justify-content: flex-end;
  padding: 0;
  background-color: styles.$white-2;
  color: styles.$blue-4;
  width: max-content;
  margin-left: 0;
  &:hover {
    text-decoration: underline;
  }
}

.galleryButton {
  background-color: styles.$white-3;
  color: styles.$blue-4;
  border: 1px solid styles.$winner-1;
  border-radius: 6px;
  &:hover {
    background-color: styles.$winner-1;
    color: styles.$white-2;
  }
}

.galleryButtonActive {
  background-color: styles.$winner-1;
  color: styles.$white-2;
  border: 1px solid styles.$winner-1;
  border-radius: 6px;
}

.share {
  flex-direction: row;
  padding: 0.8rem 1.6rem !important;
  background-color: styles.$white-3 !important;
  &:hover {
    p {
      color: styles.$white-3;
    }
    svg {
      fill: styles.$white-3;
      color: styles.$white-3 !important;
    }
    background-color: styles.$blue-4 !important;
  }
  p {
    font-weight: bold;
    color: styles.$blue-4;
    &:hover {
      color: styles.$white-3;
    }
  }
}

.shareOutline {
  @extend .share;
  border-radius: 6px;
  border: 1px solid styles.$winner-1;
}

.shareAlt {
  flex-direction: row;
  gap: 1em;
  &:hover {
    text-decoration: underline;
  }
}

.votingGradient {
  @extend .gradient-3;
  color: styles.$white-3;
  &:hover {
    background: styles.$white-3;
    color: styles.$orange-1;
    & svg {
      fill: styles.$orange-1;
    }
  }
}

.calendarActive {
  color: styles.$white-5;
  background-color: styles.$blue-4;

  &:hover {
    filter: brightness(105%);
  }
}

.calendar {
  color: styles.$blue-4;
  background-color: styles.$white-6;
  border: 1px solid styles.$blue-4;

  &:hover {
    filter: brightness(105%);
  }
}
